import {
  BOTS_SORT_KEYS,
  BOTS_SORT_TYPES,
  botsQuickFilterConstants,
  sortKeys,
} from '../../pages/Bots/botsConstants';
import * as types from './botActionTypes';

const initialState = {
  bots: null,
  filteredBots: null,
  bot: null,
  createdBot: null,
  updatedBot: null,
  deletedBot: null,
  botsSortData: {
    sortType: BOTS_SORT_TYPES.ASCENDING,
    sortKey: BOTS_SORT_KEYS.BOT_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOTS_DATA:
      let bots = action.payload;
      return {
        ...state,
        bots: bots ? [...bots] : null,
      };
    case types.GET_BOTS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOTS_ERROR,
          data: action.payload,
        },
      };
    case types.CREATE_BOT_DATA:
      return {
        ...state,
        createdProjet: action.payload,
      };
    case types.CREATE_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.CREATE_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.UPDATE_BOT_DATA:
      return {
        ...state,
        updatedBot: action.payload,
      };
    case types.UPDATE_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.UPDATE_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.DELETE_BOT_DATA:
      const deletedBotID = action.payload;

      let tempBotsForDelete = state.bots;
      const deletedBotIndex = tempBotsForDelete.findIndex(
        (p) => p.id === deletedBotID,
      );
      tempBotsForDelete.splice(deletedBotIndex, 1);

      let tempFilteredBotForDelete = state.filteredBots;
      if (tempFilteredBotForDelete) {
        const deletedFilteredBotIndex = tempFilteredBotForDelete.findIndex(
          (p) => p.id === deletedBotID,
        );
        tempFilteredBotForDelete.splice(deletedFilteredBotIndex, 1);
      }

      return {
        ...state,
        bots: tempBotsForDelete ? [...tempBotsForDelete] : state.bots,
        filteredBots: tempFilteredBotForDelete ? [...tempFilteredBotForDelete] : null,
        deletedBot: action.payload,
      };
    case types.DELETE_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.DELETE_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_BOTS:
      function filterBots(filterInput) {
        console.log('filterInput')
        console.log(filterInput)
        const filteredBots = [];

        let tempBotsForFilter = state.bots;
        if (state.quickFilter === botsQuickFilterConstants.TRUE) {
          tempBotsForFilter = tempBotsForFilter.filter((t) => t.status === true);
        } else if (state.quickFilter === botsQuickFilterConstants.FALSE) {
          tempBotsForFilter = tempBotsForFilter.filter((t) => t.status === false);
        }

        if (tempBotsForFilter) {
          for (const fBots of tempBotsForFilter) {
            if (
              fBots?.token?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredBots.push(fBots);
            }
          }

          return filteredBots;
        }
      }

      let filteredBots = null;

      filteredBots = filterBots(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredBots: filteredBots ? [...filteredBots] : null,
      };
    case types.SET_BOTS_SORT_DATA:
      return {
        ...state,
        botsSortData: { ...action.payload },
      };
      case types.SORT_BOTS:
        const tempBotsForSorting = state.filteredBots?.length
          ? state.filteredBots
          : state.bots;
        console.log("Temp Bots For Sorting:", tempBotsForSorting);
      
        const sortedBots = tempBotsForSorting?.sort((a, b) => {
          if (state.botsSortData.sortType === BOTS_SORT_TYPES.ASCENDING) {
            return (
              a.token.toString().toLowerCase().localeCompare(b.token.toString().toLowerCase())
            );
          } else {
            return (
              b.token.toString().toLowerCase().localeCompare(a.token.toString().toLowerCase())
            );
          }
        });
      
        if (state.filteredBots?.length) {
          return {
            ...state,
            filteredBots: sortedBots ? [...sortedBots] : null,
          };
        }
        return {
          ...state,
          bots: sortedBots ? [...sortedBots] : state.bots,
        };
      
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};
