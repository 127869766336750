import { all } from 'redux-saga/effects';
import { userSaga } from './user/userSaga';
import { botSaga } from './bot/botSaga';
export default function* rootSaga() {
  yield all([
    userSaga(), 
    botSaga(),

  ]);
     
}