export const GET_BOTS_REQUEST = 'GET_BOTS_REQUEST';
export const GET_BOTS_DATA = 'GET_BOTS_DATA';
export const GET_BOTS_ERROR = 'GET_BOTS_ERROR';

export const CREATE_BOT_REQUEST = 'CREATE_BOT_REQUEST';
export const CREATE_BOT_DATA = 'CREATE_BOT_DATA';
export const CREATE_BOT_ERROR = 'CREATE_BOT_ERROR';

export const UPDATE_BOT_REQUEST = 'UPDATE_BOT_REQUEST';
export const UPDATE_BOT_DATA = 'UPDATE_BOT_DATA';
export const UPDATE_BOT_ERROR = 'UPDATE_BOT_ERROR';

export const DELETE_BOT_REQUEST = 'DELETE_BOT_REQUEST';
export const DELETE_BOT_DATA = 'DELETE_BOT_DATA';
export const DELETE_BOT_ERROR = 'DELETE_BOT_ERROR';

export const UPDATE_QUICK_FILTER = 'UPDATE_QUICK_FILTER';
export const FILTER_BOTS = 'FILTER_BOTS';
export const SET_BOTS_SORT_DATA = 'SET_BOTS_SORT_DATA';
export const SORT_BOTS = 'SORT_BOTS';