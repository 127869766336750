const BOTS_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const BOTS_SORT_KEYS = {
  BOT_NAME: 0,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Bot Name', key: 'name' },
];

const botsQuickFilterConstants = {
  ALL: 1,
  TRUE: 2,
  FALSE: 3,
};

export {
  BOTS_SORT_KEYS,
  BOTS_SORT_TYPES,
  sortTypes,
  sortKeys,
  botsQuickFilterConstants,
};
