class BotConfig {
  constructor() {
    this.token = '';
    this.bot_interval = '1h';
    this.rsi_ma_type = 'SMA';
    this.rsi_ma_length = '';
    this.rsi_length = '';
    this.macd_fast_length = '';
    this.macd_slow_length = '';
    this.macd_oscillator_ma_type = 'SMA';
    this.long_stop = '';
    this.short_stop = '';
    this.macd_value = '';
    this.rsi_period = '15min';
    this.total_one_hour = false;
    this.total_four_hour = false;

    this.condition_one = {
      fifteen_min_ma: '',
      one_hour_ma: '',
      four_hour_ma: '',
      one_day_ma: '',
      one_week_ma: '',
      one_month_ma: '',
      fifteen_min_rsi: false,
      one_hour_rsi: false,
      four_hour_rsi: false,
      one_day_rsi: false,
      one_week_rsi: false,
      one_month_rsi: false,
    };
  }
}

export default BotConfig;
