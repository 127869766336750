import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MercurXBotDetailInputs.scss';
import BotConfig from '../../models/BotConfigModel';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  createBotActionRequest,
  updateBotActionRequest,
} from '../../store/bot/botActions';

const MercurXBotDetailInputs = (props) => {
  const { isEdit, createBotRequest, updateBotRequest } = props;
  const history = useHistory();
  const location = useLocation();
  const bot = location.state?.bot;

  const [botConfig, setBotConfig] = useState(new BotConfig());

  useEffect(() => {
    if (bot) {
      setBotConfig((prevConfig) => ({
        ...prevConfig,
        ...bot,
        condition_one: {
          ...prevConfig.condition_one,
          ...bot.conditionone
        }
      }));
    }
  }, [bot]);
  
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
  
    if (id.startsWith('condition_one_')) {
      const field = id.replace('condition_one_', '');
      setBotConfig((prevConfig) => ({
        ...prevConfig,
        condition_one: {
          ...prevConfig.condition_one,
          [field]: type === 'checkbox' ? checked : parseFloat(value),
        },
      }));
    } else {
      setBotConfig((prevConfig) => ({
        ...prevConfig,
        [id]: type === 'checkbox' ? checked : (id === 'macd_value' ? parseFloat(value) : value),
      }));
    }
  };

  function createBot() {
    const payload = {
      ...botConfig,
      rsi_ma_length: parseFloat(botConfig.rsi_ma_length),
      rsi_length: parseFloat(botConfig.rsi_length),
      macd_fast_length: parseFloat(botConfig.macd_fast_length),
      macd_slow_length: parseFloat(botConfig.macd_slow_length),
      long_stop: parseFloat(botConfig.long_stop),
      short_stop: parseFloat(botConfig.short_stop),
      macd_value: parseFloat(botConfig.macd_value),
    };
    console.log(payload);
    createBotRequest(payload);
    setTimeout(() => {
    history.goBack();
    }, 2000);
  }
  function updateBot() {
    const payload = {
      token: botConfig.token,
      bot_interval: botConfig.bot_interval,
      rsi_ma_type: botConfig.rsi_ma_type,
      rsi_ma_length: botConfig.rsi_ma_length,
      rsi_length: botConfig.rsi_length,
      macd_fast_length: botConfig.macd_fast_length,
      macd_slow_length: botConfig.macd_slow_length,
      macd_oscillator_ma_type: botConfig.macd_oscillator_ma_type,
      long_stop: botConfig.long_stop,
      short_stop: botConfig.short_stop,
      macd_value: botConfig.macd_value,
      rsi_period: botConfig.rsi_period,
      total_one_hour: botConfig.total_one_hour,
      total_four_hour: botConfig.total_four_hour,
      status: botConfig.status,
      condition_one: {
        fifteen_min_ma: botConfig.condition_one.fifteen_min_ma,
        one_hour_ma: botConfig.condition_one.one_hour_ma,
        four_hour_ma: botConfig.condition_one.four_hour_ma,
        one_day_ma: botConfig.condition_one.one_day_ma,
        one_week_ma: botConfig.condition_one.one_week_ma,
        one_month_ma: botConfig.condition_one.one_month_ma,
        fifteen_min_rsi: botConfig.condition_one.fifteen_min_rsi,
        one_hour_rsi: botConfig.condition_one.one_hour_rsi,
        four_hour_rsi: botConfig.condition_one.four_hour_rsi,
        one_day_rsi: botConfig.condition_one.one_day_rsi,
        one_week_rsi: botConfig.condition_one.one_week_rsi,
        one_month_rsi: botConfig.condition_one.one_month_rsi,
      },
    };
  
    console.log("Updating bot with payload:", payload);
  
    const botID = botConfig.id;
  
    updateBotRequest({ botID, body: payload }); 
  
    setTimeout(() => {
      history.goBack(); 
    }, 2000);
  }
  
  
  
  
  return (
    <div className="bot-config">
      <Form>
        <Accordion className="mt-4 shadow" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Bot</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Token*</Form.Label>
                    <Form.Control
                      id="token"
                      type="text"
                      value={botConfig.token}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Bot Interval*</Form.Label>
                    <Form.Control
                      as="select"
                      id="bot_interval"
                      value={botConfig.bot_interval}
                      onChange={handleChange}
                    >
                      <option value="1h">1 Hour</option>
                      {/* Diğer interval seçenekleri buraya eklenebilir */}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>RSI MA Type*</Form.Label>
                    <Form.Control
                      as="select"
                      id="rsi_ma_type"
                      value={botConfig.rsi_ma_type}
                      onChange={handleChange}
                    >
                      <option value="SMA">SMA</option>
                      <option value="EMA">EMA</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>RSI MA Length*</Form.Label>
                    <Form.Control
                      id="rsi_ma_length"
                      type="number"
                      value={botConfig.rsi_ma_length}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>RSI Length*</Form.Label>
                    <Form.Control
                      id="rsi_length"
                      type="number"
                      value={botConfig.rsi_length}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>MACD Fast Length*</Form.Label>
                    <Form.Control
                      id="macd_fast_length"
                      type="number"
                      value={botConfig.macd_fast_length}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>MACD Slow Length*</Form.Label>
                    <Form.Control
                      id="macd_slow_length"
                      type="number"
                      value={botConfig.macd_slow_length}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>MACD Oscillator MA Type*</Form.Label>
                    <Form.Control
                      as="select"
                      id="macd_oscillator_ma_type"
                      value={botConfig.macd_oscillator_ma_type}
                      onChange={handleChange}
                    >
                      <option value="SMA">SMA</option>
                      <option value="EMA">EMA</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Long Stop*</Form.Label>
                    <Form.Control
                      id="long_stop"
                      type="number"
                      value={botConfig.long_stop}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Short Stop*</Form.Label>
                    <Form.Control
                      id="short_stop"
                      type="number"
                      value={botConfig.short_stop}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* Condition One Bölümü */}
        <Accordion className="mt-4 shadow" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Condition One</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>15 Min MA*</Form.Label>
                    <Form.Control
                      id="condition_one_fifteen_min_ma"
                      type="number"
                      value={botConfig.condition_one?.fifteen_min_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>15 Min RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_fifteen_min_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.fifteen_min_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Hour MA*</Form.Label>
                    <Form.Control
                      id="condition_one_one_hour_ma"
                      type="number"
                      value={botConfig.condition_one?.one_hour_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Hour RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_one_hour_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.one_hour_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Month MA*</Form.Label>
                    <Form.Control
                      id="condition_one_one_month_ma"
                      type="number"
                      value={botConfig.condition_one?.one_month_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Month RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_one_month_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.one_month_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>4 Hour MA*</Form.Label>
                    <Form.Control
                      id="condition_one_four_hour_ma"
                      type="number"
                      value={botConfig.condition_one?.four_hour_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>4 Hour RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_four_hour_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.four_hour_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Day MA*</Form.Label>
                    <Form.Control
                      id="condition_one_one_day_ma"
                      type="number"
                      value={botConfig.condition_one?.one_day_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Day RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_one_day_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.one_day_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Week MA*</Form.Label>
                    <Form.Control
                      id="condition_one_one_week_ma"
                      type="number"
                      value={botConfig.condition_one?.one_week_ma}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>1 Week RSI*</Form.Label>
                    <Form.Check
                      id="condition_one_one_week_rsi"
                      type="checkbox"
                      checked={botConfig.condition_one?.one_week_rsi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* Condition Two ve Condition Three Yan Yana */}
        <Row>
          <Col md="6">
            {/* Condition Two Bölümü */}
            <Accordion className="mt-4 shadow" defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="text-fs-head-sm">Condition Two</div>
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mt-2">
                    <Form.Label>MACD Value*</Form.Label>
                    <Form.Control
                      id="macd_value"
                      type="number"
                      value={botConfig.macd_value}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>RSI Period*</Form.Label>
                    <Form.Control
                      as="select"
                      id="rsi_period"
                      value={botConfig.rsi_period}
                      onChange={handleChange}
                    >
                      <option value="15min">15 Min</option>
                      <option value="1h">1 Hour</option>
                      <option value="4h">4 Hours</option>
                      <option value="1d">1 Day</option>
                      <option value="1w">1 Week</option>
                      <option value="1m">1 Month</option>
                    </Form.Control>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md="6">
            {/* Condition Three Bölümü */}
            <Accordion className="mt-4 shadow" defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="text-fs-head-sm">Condition Three</div>
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mt-2">
                    <Form.Label>Total One Hour*</Form.Label>
                    <Form.Check
                      id="total_one_hour"
                      type="checkbox"
                      checked={botConfig.total_one_hour}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Total Four Hour*</Form.Label>
                    <Form.Check
                      id="total_four_hour"
                      type="checkbox"
                      checked={botConfig.total_four_hour}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* Boşluk eklemek için ek bir div */}
                  <div style={{ height: "27px" }}></div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        {!isEdit && (
          <Button className="mt-2" onClick={createBot}>
            Create Bot
          </Button>
        )}
         {isEdit && (
          <Button className="mt-2" onClick={updateBot}>
            Update Bot
          </Button>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bot: state.botReducer.bot,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createBotRequest: (payload) => dispatch(createBotActionRequest(payload)),
    updateBotRequest: (payload) => {
      dispatch(updateBotActionRequest(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXBotDetailInputs);
