import { useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXBotTable.scss';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { updateBotActionRequest } from '../../store/bot/botActions';
import { useHistory } from 'react-router-dom';

function MercurXBotTable(props) {
  const { updateBotRequest, bots, filteredBots, updatedBot } = props;
  const history = useHistory();

  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredBots ? filteredBots : bots);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedBots, setRenderedBots] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);

  useEffect(() => {
    setSourceData(filteredBots ? filteredBots : bots);
  }, [bots, filteredBots]);

  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);

  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedBots([...tempRendered]);
    }
  }, [currentPage, sourceData]);

  useEffect(() => {
    if (updatedBot) {
      setSourceData((prevData) =>
        prevData.map((bot) => (bot.id === updatedBot.id ? updatedBot : bot))
      );
    }
  }, [updatedBot]);

  function scrollToTokensContainer() {
    const botsContainer = document.getElementById('bots-container');
    if (botsContainer) {
      botsContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToTokensContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToTokensContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToTokensContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToTokensContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToTokensContainer();
  };

  function editBot(bot) {
    history.push({
      pathname: '/bots/edit-bot',
      state: {
        bot: bot,
      },
    });
  }

  function updateBotStatus(botID, status) {
    const payload = {
      status: !status,
    };
    setSourceData((prevData) =>
      prevData.map((bot) => (bot.id === botID ? { ...bot, status: !status } : bot))
    );
    updateBotRequest({ botID, body: payload });
  }

  const user = JSON.parse(localStorage.getItem('user'))?.user;

  return (
    <>
      <Table className="bots-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token</th>
            <th>bot_interval</th>
            <th>long_stop</th>
            <th>macd_fast_length</th>
            <th>macd_oscillator_ma_type</th>
            <th>macd_slow_length</th>
            <th>macd_value</th>
            <th>rsi_length</th>
            <th>status</th>
          </tr>
        </thead>
        {renderedBots?.length ? (
          <tbody>
            {renderedBots.map((item, index) => (
              <tr key={item.id} className="text-t-head-color">
                <td>{index + 1}</td>
                <td>{item.token}</td>
                <td>{item.bot_interval}</td>
                <td>{item.long_stop}</td>
                <td>{item.macd_fast_length}</td>
                <td>{item.macd_oscillator_ma_type}</td>
                <td>{item.macd_slow_length}</td>
                <td>{item.macd_value}</td>
                <td>{item.rsi_length}</td>
                <td>
                  {item.status === true ? (
                    <Badge bg="success">True</Badge>
                  ) : <Badge bg="danger">False</Badge>}
                </td>
                <td>
                  <div className="cursor-pointer mt-1" onClick={() => editBot(item)}>
                    <MercurXIcons
                      name="BsPencilSquare"
                      size={18}
                      color={mainColors['primary']}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-check">
                    <input
                      className='checkbox-color'
                      type="checkbox"
                      checked={item.status}
                      onChange={() => updateBotStatus(item.id, item.status)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </Table>
      {!renderedBots?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No bot found according to search results.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => (
            <Pagination.Item
              key={index}
              active={page === currentPage}
              onClick={() => changePage(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    bots: state.botReducer.bots,
    filteredBots: state.botReducer.filteredBots,
    updatedBot: state.botReducer.updatedBot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBotRequest: (payload) => {
      dispatch(updateBotActionRequest(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXBotTable);
