import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './MercurXSidebarMenu.scss';
import { Link } from 'react-router-dom';
import MultisignalsLogo from '../../assets/img/logo/logo.png';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Navbar from 'react-bootstrap/Navbar';

function MercurXSidebarMenu({ ...props }) {
  const { pathname: activePath } = useLocation();
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  function controlPath(cPath) {
    if (activePath.startsWith(cPath)) {
      return true;
    }
    return false;
  }

  return (
    <Sidebar className="bg-gray-300" backgroundColor="white">
       <div className="d-flex align-items-center justify-content-center py-3 px-2" >
        <Navbar.Brand as={Link} to="/">
          <img
            src={MultisignalsLogo}
            alt="logo"
            height={60}
          />
        </Navbar.Brand>
        </div>
      <Menu>
        <MenuItem
          icon={<MercurXIcons name="BsHouse" size={20} />}
          className={`${activePath === '/' ? 'active-menu-item' : ''}`}
          component={<Link to="/" />}
        >
          Home
        </MenuItem>
        <MenuItem
          icon={<MercurXIcons name="BsLayoutTextWindowReverse" size={20} />}
          className={`${activePath === '/bots' ? 'active-menu-item' : ''}`}
          component={<Link to="/bots" />}
        >
          Bots
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXSidebarMenu);
