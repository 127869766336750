import React, { useEffect, useState } from 'react';
import './Bots.scss';
import { connect } from 'react-redux';
import {
  filterBotsAction,
  getBotsActionRequest,
  setBotsSortDataAction,
  sortBotsAction,
  updateQuickFilterAction,
} from '../../store/bot/botActions';
import MercurXBotTable from '../../components/MercurXBotTable/MercurXBotTable';
import { Button, Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { botsQuickFilterConstants, sortKeys, sortTypes } from './botsConstants';

function Bots({ ...props }) {
  const {
    getBotsRequest,
    quickFilter,
    botsSortData,
    updateQuickFilter,
    setBotsSortData,
    sortBots,
    filterBots,
  } = props;

  const history = useHistory();
  const [filterInput, setFilterInput] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  useEffect(() => {
    getBotsRequest();
    updateQuickFilter(botsQuickFilterConstants.ALL)
  }, []);

  useEffect(() => {
    filterBots(filterInput);
    sortBots();
  }, [quickFilter]);

  useEffect(() => {
    filterBots(filterInput);
  }, [filterInput])

  function createNewBot() {
    history.push('/bots/create-new-bot');
  }

  function changeSortType(sortType) {
    const newTokenSortData = { ...botsSortData, sortType };
    setBotsSortData(newTokenSortData);
    sortBots();
  }

  function changeSortKey(sortKey) {
    const newTokenSortData = { ...botsSortData, sortKey };
    console.log(sortKey)
    setBotsSortData(newTokenSortData);
    sortBots();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Bots</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by token name"
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="bots-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="bots-sorting-section" className="d-flex align-items-center py-2">
            <Dropdown className="me-2 bots-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[botsSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="bots-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[botsSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
          <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === botsQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== botsQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={botsQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(botsQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === botsQuickFilterConstants.TRUE ? 'primary' : 'light'}
              className={
                quickFilter !== botsQuickFilterConstants.TRUE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={botsQuickFilterConstants.TRUE}
              onClick={() => updateQuickFilter(botsQuickFilterConstants.TRUE)}
            >
              True
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === botsQuickFilterConstants.FALSE ? 'primary' : 'light'}
              className={
                quickFilter !== botsQuickFilterConstants.FALSE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={botsQuickFilterConstants.FALSE}
              onClick={() => updateQuickFilter(botsQuickFilterConstants.FALSE)}
            >
              False
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="bots-container">
        <MercurXBotTable />
      </div>
      <div className="text-end">
        <Button onClick={createNewBot} size={'sm'}>
          Create new bot
        </Button>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.botReducer.quickFilter,
    botsSortData: state.botReducer.botsSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBotsRequest: (payload) => {
      dispatch(getBotsActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setBotsSortData: (payload) => {
      dispatch(setBotsSortDataAction(payload));
    },
    sortBots: (payload) => {
      dispatch(sortBotsAction(payload));
    },
    filterBots: (payload) => {
      dispatch(filterBotsAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bots);
