import { combineReducers } from 'redux';
import { alertReducer } from './alert/alertReducer';
import { loadingReducer } from './loading/loadingReducer';
import { userReducer } from './user/userReducer';
import { botReducer } from './bot/botReducer';
const rootReducer = combineReducers({
  alertReducer,
  loadingReducer,
  userReducer,
  botReducer,

});

export default rootReducer;