import React from 'react';
import './EditBot.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXBotDetailInputs from '../../components/MercurXBotDetailInputs/MercurXBotDetailInputs';
import { useHistory, useLocation } from 'react-router';

function EditBot({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const bot = location.state?.bot;

  if (!bot) {
    history.goBack();
  }

  if (bot) {
    return (
      <div>
        <MercurXBackButton buttonText="Back to bot list" />
        <div className="text-fs-head-sm">Edit bot</div>
        <MercurXBotDetailInputs isEdit={true}/>
      </div>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBot);
