import * as types from './botActionTypes';

export const getBotsActionRequest = (payload) => {
  return {
    type: types.GET_BOTS_REQUEST,
    payload,
  };
};

export const getBotsActionData = (payload) => {
  return {
    type: types.GET_BOTS_DATA,
    payload,
  };
};

export const getBotsActionError = (payload) => {
  return {
    type: types.GET_BOTS_ERROR,
    payload,
  };
};

export const createBotActionRequest = (payload) => {
  return {
    type: types.CREATE_BOT_REQUEST,
    payload,
  };
};

export const createBotActionData = (payload) => {
  return {
    type: types.CREATE_BOT_DATA,
    payload,
  };
};

export const createBotActionError = (payload) => {
  return {
    type: types.CREATE_BOT_ERROR,
    payload,
  };
};

export const updateBotActionRequest = (payload) => {
  return {
    type: types.UPDATE_BOT_REQUEST,
    payload,
  };
};

export const updateBotActionData = (payload) => {
  return {
    type: types.UPDATE_BOT_DATA,
    payload,
  };
};

export const updateBotActionError = (payload) => {
  return {
    type: types.UPDATE_BOT_ERROR,
    payload,
  };
};

export const deleteBotActionRequest = (payload) => {
  return {
    type: types.DELETE_BOT_REQUEST,
    payload,
  };
};

export const deleteBotActionData = (payload) => {
  return {
    type: types.DELETE_BOT_DATA,
    payload,
  };
};

export const deleteBotActionError = (payload) => {
  return {
    type: types.DELETE_BOT_ERROR,
    payload,
  };
};

export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterBotsAction = (payload) => {
  return {
    type: types.FILTER_BOTS,
    payload,
  };
};

export const setBotsSortDataAction = (payload) => {
  return {
    type: types.SET_BOTS_SORT_DATA,
    payload,
  };
};
export const sortBotsAction = (payload) => {
  return {
    type: types.SORT_BOTS,
    payload,
  };
};