import React from 'react';
import './CreateNewBot.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXBotDetailInputs from '../../components/MercurXBotDetailInputs/MercurXBotDetailInputs';

function CreateNewBot({ ...props }) {
  return (
    <div>
      <MercurXBackButton buttonText="Back to bot list" />
      <div className="text-fs-head-sm">Create New Bot</div>
      <MercurXBotDetailInputs showBot={true} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewBot);
