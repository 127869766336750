import MainLayout from './layouts/MainLayout/MainLayout';
import PublicLayout from './layouts/PublicLayout/PublicLayout';

import Home from './pages/Home/Home';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { Route } from 'react-router-dom';
//import UserRoute from './routes/UserRoute';
import Login from './pages/Login/Login';
import UserRoute from './routes/UserRoute';
import Bots from './pages/Bots/Bots';
import CreateNewBot from './pages/CreateNewBot/CreateNewBot';
import EditBot from './pages/EditBot/EditBot'; 
import Activation from './pages/Activation/Activation';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
const routes = {
  PublicLayout: {
    exact: true,
    name: 'Public',
    component: PublicLayout,
    children: [
      {
        path: '/login',
        exact: true,
        name: 'Home',
        component: Login,
        routeComponent: Route,
      },
      {
        path: '/activate_user',
        exact: true,
        name: 'Activation',
        component: Activation,
        routeComponent: Route,
      },
      {
        path: '/forgotpassword',
        exact: true,
        name: 'Forgot Password',
        component: ForgotPassword,
        routeComponent: Route,
      },
      {
        path: '/reset-password',
        exact: true,
        name: 'Reset Password',
        component: ResetPassword,
        routeComponent: Route,
      },
    ],
  },
  MainLayout: {
    exact: true,
    name: 'Main',
    component: MainLayout,
    children: [
      {
        path: '/',
        exact: true,
        name: 'Home',
        component: Home,
        routeComponent: UserRoute,
      },
      {
        path: '/bots',
        exact: true,
        name: 'Bots',
        component: Bots,
        routeComponent: UserRoute,
      },
      {
        path: '/bots/create-new-bot',
        exact: true,
        name: 'Create New Bot',
        component: CreateNewBot,
        routeComponent: UserRoute,
      },
      {
        path: '/bots/edit-bot',
        exact: true,
        name: 'Edit Bot',
        component: EditBot,
        routeComponent: UserRoute,
      },

    ],
  },
};

export default routes;
