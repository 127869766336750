import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as types from './botActionTypes';
import * as actions from './botActions';
import * as endpoints from '../../services/endpoints';
import * as alert from '../alert/alertActions';
import { errorHandler } from '../../helpers/errorHandler';
//Worker Sagas

function* getBotsSaga(action) {
  try {
    const { data } = yield call(endpoints.getBots);
    yield put(actions.getBotsActionData(data));
    yield put(actions.sortBotsAction());
  } catch (e) {
    yield put(actions.getBotsActionError(e));
  }
}

function* createBotSaga(action) {
  try {
    const { data } = yield call(endpoints.createBot, action.payload);
    yield put(actions.createBotActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "Bot creation process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.createBotActionError(e));
  }
}

function* updateBotSaga(action) {
  try {
    const { botID, body } = action.payload;
    console.log(action.payload)
    console.log(botID);
    console.log(body);
    const { data } = yield call(endpoints.updateBot, botID, body);
    console.log(data);
    yield put(actions.updateBotActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "The bot update process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.updateBotActionError(e));
  }
}

function* deleteBotSaga(action) {
  try {
    const { botID } = action.payload;
    console.log('deleteBotSaga: ', botID);
    const { data } = yield call(endpoints.deleteBot, botID);
    yield put(actions.deleteBotActionData(botID));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "The bot deletion process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.deleteBotActionError(e));
  }
}

//Watcher Sagas
function* watchGetBots() {
  yield takeEvery(types.GET_BOTS_REQUEST, getBotsSaga);
}
function* watchCreateBot() {
  yield takeEvery(types.CREATE_BOT_REQUEST, createBotSaga);
}

function* watchUpdateBot() {
  yield takeEvery(types.UPDATE_BOT_REQUEST, updateBotSaga);
}

function* watchDeleteBot() {
  yield takeEvery(types.DELETE_BOT_REQUEST, deleteBotSaga);
}

export function* botSaga() {
  yield all([watchGetBots(), watchDeleteBot(), watchCreateBot(), watchUpdateBot()]);
}
